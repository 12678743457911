import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import AnimatedImage from 'components/image/animated-image'
import styles from './hero.module.css'

const query = graphql`
  query {
    triangleLeft: file(relativePath: { eq: "triangle.png" }) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    triangleRight: file(relativePath: { eq: "right-corner-triangle.png" }) {
      childImageSharp {
        fluid(maxWidth: 850, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

const Hero = ({ children, content, featuredImage }) => {
  const data = useStaticQuery(query)
  const leftTriangle = data.triangleLeft.childImageSharp.fluid
  const rightTriangle = data.triangleRight.childImageSharp.fluid

  return (
    <section className={styles.container}>
      <Img fluid={rightTriangle} className={styles.leftTriangleWhite} />
      <Img fluid={leftTriangle} className={styles.leftTriangle} />

      <h1 dangerouslySetInnerHTML={{ __html: children }} className={styles.title}></h1>

      <div className={styles.content}>
        <div dangerouslySetInnerHTML={{ __html: content }} className={styles.text}></div>
        <div className={styles.imageWrapper}>
          <Img fluid={rightTriangle} className={styles.rightTriangle} />
          { featuredImage && <AnimatedImage image={featuredImage} className={styles.image} /> }
        </div>
      </div>
    </section>
  )
}

Hero.defaultProps = {
  children: '',
  content: '',
  featuredImage: {}
}

export default Hero