import React, { Component } from 'react'
import { graphql } from 'gatsby'

import SEO from  'components/seo'
import ContentBlock from 'components/layouts/content-block'
import Hero from 'components/layouts/hero'
import Footer from 'components/footer/footer'
import { createLayout } from 'components/layouts'

class PageTemplate extends Component {
  render() {
    const currentPage = this.props.data.wordpressPage
    const acf = currentPage.acf.content_layout_page
    const slug = currentPage.slug
    const featuredMedia = currentPage.featured_media
    let featuredImage = {}

    if (featuredMedia && featuredMedia.localFile) {
      featuredImage = {
        fluid: featuredMedia.localFile.childImageSharp.fluid,
        alt_text: featuredMedia.alt_text
      }
    }
    
    let flexibleContent = null

    if (acf) {
      flexibleContent = createLayout(acf, slug)
    }

    return (
      <>
        <SEO title={currentPage.title} yoast={currentPage.yoast_meta && currentPage.yoast_meta} />

        <Hero content={ currentPage.acf.blurb } featuredImage={featuredImage}>{ currentPage.acf.custom_title || currentPage.title }</Hero>
        { currentPage.content && <ContentBlock>{ currentPage.content }</ContentBlock> }
        { flexibleContent }

        <Footer />
      </>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      slug
      date(formatString: "MMMM DD, YYYY")
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_twitter_description
        yoast_wpseo_twitter_image
        yoast_wpseo_twitter_title
        yoast_wpseo_website_name
        yoast_wpseo_person_name
        yoast_wpseo_metadesc
        yoast_wpseo_facebook_type
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_image
        yoast_wpseo_facebook_description
        yoast_wpseo_company_or_person
        yoast_wpseo_company_name
        yoast_wpseo_company_logo
        yoast_wpseo_social_defaults {
          facebook_site
          instagram_url
          linkedin_url
          myspace_url
          og_default_image
          og_default_image_id
          og_frontpage_desc
          og_frontpage_image
          og_frontpage_image_id
          og_frontpage_title
          opengraph
          pinterest_url
          pinterestverify
          twitter
          twitter_card_type
          twitter_site
          wikipedia_url
          youtube_url
        }
      }
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 650, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      acf {
        blurb
        custom_title
        content_layout_page {
          __typename
          ... on WordPressAcf_call_to_action {
            id
            title
            title_emphasis
            content
            page_link {
              target
              title
              url
            }
            cta_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600, quality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
          ... on WordPressAcf_bordered_content {
            id
            bottom_border
            content
          }
          ... on WordPressAcf_bordered_content_with_button {
            id
            bottom_border
            content
            page_link {
              target
              title
              url
            }
          }
          ... on WordPressAcf_borderless_content {
            id
            content
            subtitle
            title
            two_columns
          }
          ... on WordPressAcf_contact_form {
            id
            content
          }
          ... on WordPressAcf_image_collage {
            id
            alternative_arrangement
            image_gallery {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
          ... on WordPressAcf_service_list {
            id
            services {
              content
              icon
              title
            }
          }
          ... on WordPressAcf_team_members {
            id
            team {
              name
              picture {
                wordpress_id
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                      base64
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                      presentationWidth
                      presentationHeight
                    }
                  }
                }
              }
              title
            }
            title
          }
          ... on WordPressAcf_giant_button {
            id
            page_link {
              target
              title
              url
            }
            title
            subtitle
          }
          ... on WordPressAcf_horizontal_divider {
            id
            color
          }
          ... on WordPressAcf_contact_form_with_info {
            title
            information
            id
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`