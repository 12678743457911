import React from 'react'

import styles from './content-block.module.css'

const ContentBlock = ({ children }) => (
  <section className={styles.container} dangerouslySetInnerHTML={{ __html: children }}></section>
)

ContentBlock.defaultProps = {
  content: ''
}

export default ContentBlock